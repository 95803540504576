<template>
    <div class="language-box">
        <el-dropdown @command="handleCommand">
            <div class="language-drop">
                <!-- <img v-if="languageName == 'English'" class="avatar"
                    :src="require('@/assets/images/center/english@1x.png')" alt="" />
                <img v-else class="avatar" :src="require('@/assets/images/center/china.png')" alt="" /> -->
                <img v-if="languageName == 'English'" class="avatar" :src="langPNG" alt="" />
                <img v-else class="avatar" :src="langPNG" alt="" />
                <span>{{ languageName }}</span>
                <el-icon>
                    <ArrowDownBold />
                </el-icon>
            </div>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item v-for="item in languageList" :key="item.value" :command="item.value">
                        <div class="langrage_content">
                            <img :src="item.imgURL" alt="" style="width: 2rem;margin-right: 1rem;">
                            <div>{{ item.name }}</div>
                        </div>
                    </el-dropdown-item>
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </div>
</template>

<script setup>
import { ref, unref } from 'vue';
import { ClickOutside as vClickOutside, ElMessage } from 'element-plus';
import { ArrowDownBold } from '@element-plus/icons-vue';
import ch from '@/assets/images/center/china.png'
import en from '@/assets/images/center/english@1x.png'
import cn from '@/assets/images/center/cn.svg'
import uk from '@/assets/images/center/uk.svg'
import langPNG from '@/assets/images/center/checkLang.png'
const languageList = [
    {
        name: '简体中文',
        value: 'chinese',
        imgURL: cn
    },
    {
        name: 'English',
        value: 'english',
        imgURL: uk
    }
];

const val = localStorage.getItem('langrage') || 'chinese';

const languageName = languageList.find((item) => item.value == val)
    ? languageList.find((item) => item.value == val).name
    : '简体中文';
console.log(languageName);
const buttonRef = ref();
const popoverRef = ref();
const handleCommand = (lang) => {
    ////////////////////////////
    const urlParams = new URLSearchParams(window.location.search);
    const langParam = urlParams.get('lang');
    // 获取当前的URL对象
    const url = new URL(window.location.href);

    if (langParam) {
        // 删除'lang'参数
        urlParams.delete('lang');

        // 将修改后的URLSearchParams对象转换回查询字符串
        // const newSearchString = urlParams.toString();

        // 更新window.location.search以反映更改
        // 注意：直接设置window.location.search会导致页面重新加载
        url.search = urlParams.toString() ? '?' + urlParams.toString() : '';
        localStorage.setItem('langrage', lang);
        window.location.href = url.href;
    } else {
        /////
        window.location.reload();
        localStorage.setItem('langrage', lang);
        // 解决舒刷新后langrage清空问题
        sessionStorage.setItem('langrage', lang);
    }


};
const onClickOutside = () => {
    unref(popoverRef).popperRef?.delayHide?.();
};
</script>

<style lang="less">
.langrage_content {
    display: flex;
    align-items: center;
}

.language-pop {
    padding: 0 !important;
    width: auto !important;
    min-width: auto !important;
}
</style>
<style lang="less" scoped>
.language-drop {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    outline: none;
    cursor: pointer;

    .avatar {
        width: 3rem;
        display: block;
    }

    span {
        padding: 0 1rem;
        white-space: nowrap;
    }
}

.language-box {
    position: fixed;
    top: 1.5rem;
    right: 2rem;
    z-index: 100;
}

.language-list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
        padding: 1.5rem 3rem;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
            background: rgba(246, 251, 251, 1);
        }
    }
}
</style>
