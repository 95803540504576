<template>
    <div class="sidebar">
        <div class="logo flex-center" :style="{ width: isCollapse ? '8rem' : '26rem' }">
            <div @click="goHome">
                <img v-show="isCollapse" class="img1" src="@/assets/images/center/slidebar_logo.png" alt="" />
                <img v-show="!isCollapse" class="img2" src="@/assets/images/center/menu_logo.png" alt="" />
            </div>
        </div>
        <el-menu active-text-color="#ffff" background-color="#1B1D20" text-color="#fff" :default-active="currentPath"
            class="sidebar-menu" :style="{ width: isCollapse ? '8rem' : '26rem' }" :collapse="isCollapse"
            :collapse-transition="false" @select="handleSelect">
            <!-- 遍历菜单 -->
            <template v-for="(item, index) in menuList" :key="index">
                <!-- 含有子菜单 -->
                <template v-if="item.children && item.children.length > 0 && !item.meta?.hide">
                    <!-- 第一层 含有子菜单菜单 -->
                    <el-sub-menu :index="item.path">
                        <template #title>
                            <img class="icon" :src="(item.meta.icon ? item.meta.icon : '')" alt="" />
                            <span>{{ item.meta.title }}</span>
                        </template>
                        <!-- 第二层 子菜单遍历 -->
                        <el-menu-item v-for="(secondItem, idx) in item.children" v-show="!('show' in secondItem.meta)"
                            :key="idx" :index="item.path + '/' + secondItem.path">
                            <img style="margin-left: 3.5rem" class="icon"
                                :src="(secondItem.meta.icon ? secondItem.meta.icon : '')" alt="" />
                            {{ secondItem.meta.title }}
                        </el-menu-item>
                    </el-sub-menu>
                </template>
                <!-- 不含子菜单 -->
                <template v-else>
                    <el-menu-item :index="item.path">
                        <img class="icon" :src="(item.meta.icon ? item.meta.icon : '')" alt="" />
                        <template #title>
                            {{
                                item.meta.title === rocket ? rocket : item.meta.title === langrage['price'] ? rocket :
                                    item.meta.title
                            }}
                            <img v-if="item.meta.title == langrage['helpCenter']" class="menu_link"
                                src="../../assets/images/center/menu_link.png" alt="" />
                        </template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script setup>
import { Document, Menu as IconMenu, Location, Setting } from '@element-plus/icons-vue';
import { ref, inject, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
// import menuList from '@/center/menuList'

const langrage = inject('langrage');
const store = useStore();
const isCollapse = computed(() => store.state.sidebarOpen);
const router = useRouter();
const route = useRoute();
const currentPath = ref('/');
// 不需要展示在左侧菜单的路由
const hideMenu = ['/login', '/register', '/resetPassword', '/confirmPassword',];
const menuList = ref([]);

let lang = localStorage.getItem('langrage');
const goHome = () => {
    // if (lang === 'english') {
    //     window.location.href = 'https://iprocket.io?lang=en';
    //     // window.location.href = 'http://localhost:8081?lang=en';
    // }
    // if (lang === 'chinese') {
    //     window.location.href = 'https://iprocket.io?lang=zh';
    //     // window.location.href = 'http://localhost:8081?lang=zh';
    // }
    if (lang === 'english') {
        window.location.href = '/';
    }
    if (lang === 'chinese') {
        window.location.href = '/';
    }
};
// 处理路由
const handleRoutes = () => {
    const { routes } = router.options;
    hideMenu.forEach((it) => {
        routes.forEach((item, index) => {
            if (it === item.path) {
                routes.splice(index, 1);
            }
        });
    });
    menuList.value = routes;
    console.log(routes, '79===');
};

const handleSelect = (key, keyPath) => {
    console.log(keyPath);
    if (key == '/helpCenter') {
        window.open('https://help.iprocket.io/');
        return;
    }
    currentPath.value = key;
    router.push({
        path: key
    });
};

const toDashboard = () => {
    router.push('/');
    setTimeout(() => {
        location.reload();
    }, 200);
};
const rocket = langrage['rocket'];
onMounted(() => {
    console.log('重新进入菜单栏');

    handleRoutes();
    // console.log(route.path, '104===')
    // return
    console.log('path' + route.path);
    if (route.path == '/dashboard') {
        currentPath.value = `/${route.path.split('/')[0]}`;
    } else if (route.meta.hide) {
        currentPath.value = `/${route.path.split('/')[1]}`;
    } else {
        // let len = route.path.split('/').length;
        // currentPath.value = `${route.path.split('/')[len - 1]}`;
        currentPath.value = route.path;
    }
});
const handleRouteChange = (newRoute) => {
    // 更新当前路径
    // currentPath.value = newRoute.path;
    // currentPath.value = newRoute.path.split('?')[0].split('#')[0];
    if (route.path == '/dashboard') {
        currentPath.value = `/${route.path.split('/')[0]}`;
    } else if (route.meta.hide) {
        currentPath.value = `/${route.path.split('/')[1]}`;
    } else {
        // let len = route.path.split('/').length;
        // currentPath.value = `${route.path.split('/')[len - 1]}`;
        currentPath.value = route.path;
    }
    // // 更新菜单展开和选中状态
    // // 这里需要根据 newRoute.path 来找到对应的菜单项，并设置其状态
    // // 假设你的 menuList 中的每个菜单项都有一个 path 属性对应路由路径
    // const updateMenuStatus = (menuItems, path) => {
    //     for (const item of menuItems) {
    //         if (item.path === path || (item.children && item.children.some(child => child.path === path))) {
    //             // 找到匹配的菜单项，更新其状态
    //             // 这里可能需要额外的逻辑来处理子菜单的展开
    //             // 例如，如果路径匹配子菜单，则需要展开其父菜单
    //             break; // 假设每个路径唯一，找到后退出循环
    //         } else if (item.children) {
    //             // 递归检查子菜单
    //             updateMenuStatus(item.children, path);
    //         }
    //     }
    // };

    // updateMenuStatus(menuList.value, newRoute.path);
};

// 监听路由变化
watch(
    () => route,
    (newRoute) => {
        handleRouteChange(newRoute); // 使用最新的 route 对象
    },
    { immediate: true, deep: true } // 立即执行一次回调，以处理组件首次加载时的路由状态
);
</script>

<style lang="less" scoped>
.sidebar {
    height: 100vh;
    background: #1b1d20;

    .logo {
        margin: 0 auto;
        height: 8rem;
        font-size: 3rem;
        color: #fff;
        transition: 0.5s;

        .img1 {
            cursor: pointer;
            width: 5rem;
        }

        .img2 {
            cursor: pointer;
            width: 13.2rem;
            height: 3.7rem;
        }
    }

    .sidebar-menu {
        box-sizing: border-box;
        padding: 0 1rem;
        height: calc(100vh - 8rem);
        overflow-y: scroll;
        transition: 0.5s;
    }

    .icon {
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 1rem;
    }

    .menu_link {
        position: absolute;
        right: 2.4rem;
        width: 2.4rem;
    }
}

::v-deep {
    .el-menu {
        border-right: none;
    }

    .el-menu-item.is-active {
        background: #333333;
        border-radius: 0.5rem;
    }

    .el-menu-item .el-menu-tooltip__trigger {
        padding: 2rem;
    }

    .el-sub-menu .el-menu-item,
    .el-menu-item,
    .el-sub-menu__title {
        height: 6rem;
        line-height: 6rem;
    }

    .el-menu-item,
    .el-sub-menu__title {
        font-size: 1.4rem;
        padding: 2rem;
    }
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
