<script setup>
import { useRouter } from 'vue-router';
import { Fold, Expand, BellFilled, ArrowDown, ArrowRight } from '@element-plus/icons-vue';
import { ElMessage, ClickOutside as vClickOutside } from 'element-plus';
import { computed, onMounted, ref, unref, inject, watch } from 'vue';
import { useStore } from 'vuex';
import { require } from '@/utils/index';
import { getFontMsgApi } from '@/apis/common';
import { Vue3Marquee } from 'vue3-marquee';
import Breadcrumb from '../Breadcrumb';
import language from '@/components/center/language.vue';
import profile_avatar from '@/assets/images/center/profile_avatar.png';
import { readContent } from '@/apis/common'

const langrage = inject('langrage');
const router = useRouter();
const store = useStore();
const sidebarOpen = computed(() => store.state.sidebarOpen);
const userInfo = computed(() => store.state.user.userInfo);
const userMessage = computed(() =>
    store.state.user.userMessage
)
console.log('主页数据', store.state.user.userMessage);
const badge = ref('')
watch(userMessage, (nv) => {
    if (nv.length) {
        badge.value = 0
        nv.forEach(el => {
            if (el.isRead == 2) {
                badge.value = Number(badge.value) + 1
            }
        });
        if (!badge.value) {
            badge.value = ''
        }
    } else {
        badge.value = ''
    }
    // nv.length ? badge.value = nv.length : badge.value = ''
    // badge.value = nv.length
}, { deep: true })
const fontMsg = ref({});
const fontFamily = ref('');
const subUser = ref(userInfo.value['user']);
const lang = localStorage.getItem('langrage');
const visible = ref(false)
console.log('userInfo:', subUser);
const handleCommand = (command) => {
    ElMessage(`click on item ${command}`);
};

const changeSidebar = () => {
    store.commit('TOGGLE_SIDEBAR');
};

const jumpPage = (path) => {
    router.push(path);
    setTimeout(() => {
        location.reload();
    }, 100);
};

// 获取公告信息
const getFontMsg = async () => {
    try {
        const { data, code, msg } = await getFontMsgApi();
        console.log(data, '44===');
        fontMsg.value = data;
        fontFamily.value = `'Be Vietnam Pro', 'source-han-sans', sans-serif`;
    } catch (error) {
        console.error(error);
    }
};

const changeType = async (item) => {
    console.log('先进入');


    let Timer = null

    if (item.isRead == 2) {
        //先前端改变状态,让使用变得丝滑
        store.commit('user/CHANGE_MESSAGE_TYPE', item.id)
        //然后找到传来的id,调接口把未读变为已读
        await readContent({ userId: userInfo.value.user.userId, id: item.id })
        //要做个防抖,我们只更新最后一次的消息数据,防止点了未读马上调接口回头影响其他数据
        if (Timer) {
            clearTimeout(Timer)
        }
        Timer = setTimeout(async () => {
            //获取铃铛信息
            await store.dispatch('user/getRemindContent');
        }, 500)
    } else if (!item) {
        //如果传的是空就是一键已读,调接口
        console.log(1, '如果不存在item');
        visible.value = false
        await readContent({ userId: userInfo.value.user.userId, id: null })
        console.log(2);
        //获取铃铛信息
        await store.dispatch('user/getRemindContent');
        console.log(3);

    }

}
const goMessage = () => {
    router.push('/account/profile');
};
const checkIn = async () => {
    //localStorage.clear();
    await store.dispatch('user/logout');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('response');
    localStorage.removeItem('token');
    window.location.reload();
};

onMounted(() => {
    getFontMsg();
    const dropDownTrigger = document.getElementById('popover')
    console.log(dropDownTrigger);

    dropDownTrigger.addEventListener('mouseenter', () => {
        console.log(121212);
        visible.value = true
        console.log('userMessage', userMessage);
        console.log('userMessage', store.state.user.userMessage);
    });
    dropDownTrigger.addEventListener('mouseleave', () => {
        if (!store.state.user.userMessage.length) {
            visible.value = false
        }
    });

});



</script>

<template>
    <div class="navbar">
        <div class="left flex-center">
            <div class="icon" @click="changeSidebar">
                <img :style="{
                    transform: sidebarOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                }" src="@/assets/images/center/menuFold.png" alt="" />
            </div>
            <!-- <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/' }"> 仪表盘 </el-breadcrumb-item>
      </el-breadcrumb> -->
            <div style="width: 29rem">
                <Breadcrumb />
            </div>
        </div>
        <div class="tips" style="font-size: 1.4rem; width: 92rem">
            <Vue3Marquee :duration="lang == 'chinese' ? fontMsg.flowSecond : fontMsg.flowSecond2" :pauseOnHover="true">
                <span v-if="lang == 'chinese'" :style="{ fontFamily: fontFamily, color: fontMsg.colors }">{{
                    fontMsg.valueInfo
                }}</span>
                <span v-if="lang == 'english'" :style="{ fontFamily: fontFamily, color: fontMsg.colors }">{{
                    fontMsg.valueInfo2
                }}</span>
            </Vue3Marquee>
        </div>
        <div class="right flex-center">
            <el-popover :visible="visible" placement="top" :width="360">
                <div class="popover_content">
                    <p v-if="userMessage.length" class="popover_title" style="text-align: center;margin: 0;">{{
                        langrage['dashboard31'] }}</p>
                    <div class="popover_tip_content">
                        <div v-if="userMessage.length" class="popover_text_content"
                            v-for="(item, index) in userMessage">
                            <div class="num_content">
                                <p class="num">{{ index + 1 }}</p>
                                <p class="popover_text">
                                    {{ item.pushInfo }}</p>
                            </div>
                            <div class="popover_type" @click="changeType(item)" :class="item.isRead == 2 ? 'blue' : ''">
                                <!-- 35已读36未读 -->
                                {{ item.isRead == 2 ? langrage['dashboard36'] :
                                    langrage['dashboard35']
                                }}
                            </div>
                        </div>
                        <div v-else style="text-align: center;margin: 0;">{{ langrage['dashboard38'] }}</div>
                    </div>
                    <div v-if="userMessage.length" style="text-align: right; margin: 0" class="popover_botton_content">
                        <el-button size="small" text @click="visible = false" class="">{{ langrage['dashboard37']
                            }}</el-button>
                        <el-button size="small" @click="changeType('')" color="#2b6bff" type="primary" class="">
                            {{ langrage['dashboard32'] }}
                        </el-button>
                    </div>
                </div>
                <template #reference>
                    <div style="height: 100%;margin-left: 1rem;" id="popover">
                        <el-badge class="badge" :value="badge" :max="99" :offset="[3, -1]">
                            <img v-if="!badge" :src="require('@/assets/images/center/notice.png')" ref="buttonRef"
                                alt="" style="cursor: pointer; width: 2rem" />
                            <img v-else :src="require('@/assets/images/center/notice.png')" ref="buttonRef" alt=""
                                style="cursor: pointer; width: 2rem" />
                        </el-badge>
                    </div>
                </template>
            </el-popover>

            <div class="user-info flex-center" @click="jumpPage('/account/profile')">
                <span>{{ userInfo.user.userName }}</span>
                <el-dropdown class="flex-center">
                    <img class="avatar" :src="profile_avatar" alt="" />
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item command="zh" @click="goMessage">{{ langrage['profile1']
                                }}</el-dropdown-item>
                            <el-dropdown-item command="es" @click="checkIn">{{ langrage['navbar4'] }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
            <div class="btn flex-center" @click="jumpPage('/rocketWallet/index')" style="margin-right: 2rem;">
                <img class="icon" :src="require('@/assets/images/center/Wallet_balance.png')" alt="" />
                <span>{{ langrage['centerNav_t1'] }}${{ userInfo.user.walletBalance }}</span>
                <el-icon>
                    <ArrowRight />
                </el-icon>
            </div>
            <div class="select flex-center">
                <language />
                <!-- <img
          class="avatar"
          :src="require('@/assets/images/center/china.png')"
          alt=""
        /> -->
                <!-- <el-dropdown @command="handleCommand" class="flex-center">
          <span class="el-dropdown-link">
            中文
            <el-icon><arrow-down /></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="zh" @click="setLangrage('chinese')">中文</el-dropdown-item>
              <el-dropdown-item command="es" @click="setLangrage('english')">英文</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown> -->
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
.blue {
    color: #2b6bff !important;
}

.popover_content {
    .popover_title {
        margin-bottom: 1rem !important;
        font-size: 1.6rem;
    }

    .popover_tip_content {
        max-height: 14rem;
        overflow-y: auto;
    }

    .popover_text_content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .num_content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .num {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2rem;
                margin: 0.2rem 0 0.2rem 0;
            }

            .popover_text {
                margin: 0.2rem 0 0.2rem 0;
            }

        }

        .popover_type {
            color: #ccc;
            cursor: pointer;
        }
    }

    .popover_botton_content {
        padding-top: 1rem;
    }
}

// otf-opentype
// ttf-truetype
@font-face {
    font-family: 'Custom1';
    src: url('@/assets/fonts/1.ttf') format('truetype');
}

@font-face {
    font-family: 'Custom2';
    src: url('@/assets/fonts/2.ttf') format('truetype');
}

@font-face {
    font-family: 'Custom3';
    src: url('@/assets/fonts/3.ttf') format('truetype');
}

@font-face {
    font-family: 'Custom4';
    src: url('@/assets/fonts/4.ttf') format('truetype');
}

@font-face {
    font-family: 'Custom5';
    src: url('@/assets/fonts/5.ttf') format('truetype');
}

@font-face {
    font-family: 'Custom6';
    src: url('@/assets/fonts/6.ttf') format('truetype');
}

@font-face {
    font-family: 'Custom7';
    src: url('@/assets/fonts/7.ttf') format('truetype');
}

@font-face {
    font-family: 'Custom8';
    src: url('@/assets/fonts/8.otf') format('opentype');
}

::v-deep {
    .el-breadcrumb {
        font-size: 1.4rem;
    }
}

.scroll {
    border: 1px solid red;
    width: 50rem;
    overflow: hidden;
}

.navbar {
    position: fixed;
    z-index: 9;
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 6rem;
    background: #ffffff;
    box-shadow: inset 0rem -0.1rem 0rem 0rem #f0f0f0;
    box-sizing: border-box;
    padding: 0 2rem 0 0;

    .left {
        .icon {
            width: 4rem;
            height: 4rem;
            line-height: 5.4rem;
            text-align: center;
            cursor: pointer;

            img {
                width: 2rem;
                height: 2rem;
            }
        }

        .tips {
            font-weight: 400;
            font-size: 1.4rem;
            color: #333333;
            margin-left: 5rem;
        }
    }

    .right {
        .btn {
            cursor: pointer;
            min-width: 12.5rem;
            height: 3rem;
            background: #eff5ff;
            border-radius: 0.5rem;
            border: 0.1rem solid #2b6bff;
            font-weight: 400;
            font-size: 1.4rem;
            color: #2b6bff;

            .icon {
                width: 1.4rem;
                height: 1.3rem;
            }

            span {
                margin: 0 0.5rem 0 1rem;
                font-weight: 400;
                font-size: 1.4rem;
                color: #2b6bff;
                white-space: nowrap;
            }
        }

        .badge {
            margin: 0 2.5rem 0 1.5rem;
            cursor: pointer;
        }

        .user-info {
            cursor: pointer;
            margin-right: 2rem;

            span {
                font-weight: 500;
                font-size: 1.4rem;
                color: #333333;
                margin-right: 2rem;
            }

            .avatar {
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
            }

            .avatar:focus-visible {
                outline: none;
            }
        }

        .select {
            cursor: pointer;

            ::v-deep {
                .language-box {
                    position: relative;
                    top: auto;
                    right: auto;
                }
            }

            img {
                width: 3rem;
                height: 2rem;
                margin-right: 1rem;
            }

            .el-dropdown-link {
                font-weight: 400;
                font-size: 1.2rem;
                color: #333333;
            }

            .el-dropdown-link:focus-visible {
                outline: none;
            }
        }
    }
}

::v-deep {
    .el-dropdown-menu__item {
        font-size: 1.4rem;
    }

    .el-dropdown-menu__item:not(.is-disabled):focus,
    .el-dropdown-menu__item:not(.is-disabled):hover {
        color: #2b6bff;
    }

    .is-text {
        border: 1px solid #ccc !important;
    }
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
