import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
/* Layout */
import Layout from '@/layout';
import { require } from '@/utils/index';
console.log('来到了路由');

const centerRoutes_chinese = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login.vue'),
    meta: { title: '登录', icon: 'home', hide: true }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/center/register.vue'),
    meta: { title: '注册', icon: 'home', hide: true }
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: () => import('@/views/center/resetPassword.vue'),
    meta: { title: '重置密码', icon: 'home', hide: true }
  },
  {
    path: '/confirmPassword',
    name: 'ConfirmPassword',
    component: () => import('@/views/confirmPassword.vue'),
    meta: { title: '确认密码', icon: 'home', hide: true }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    meta: { title: '仪表盘', icon: require('@/assets/images/center/menu_dashboard.png'), hide: true },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard.vue')
      }
    ]
  },
  {
    path: '/product',
    component: Layout,
    redirect: '/product/price',
    name: 'Product',
    meta: { title: '住宅代理', icon: require('@/assets/images/center/home_icon.png') },
    children: [
      {
        path: 'price',
        name: 'Price',
        component: () => import('@/views/center/product/price.vue'),
        meta: { title: '价格', icon: require('@/assets/images/center/menu_price.png') }
      },
      {
        path: 'proxyAccess',
        name: 'ProxyAccess',
        component: () => import('@/views/center/product/proxyAccess.vue'),
        meta: { title: '获取IP', icon: require('@/assets/images/center/menu_proxy.png') }
      },
    //   {
    //     path: 'proxyAccounts',
    //     name: 'ProxyAccounts',
    //     component: () => import('@/views/center/product/proxyAccounts.vue'),
    //     meta: { title: '代理账户', icon: require('@/assets/images/center/menu_proxy_accounts.png') }
    //   },
    //   {
    //     path: 'whitelist',
    //     name: 'Whitelist',
    //     component: () => import('@/views/center/product/whitelist.vue'),
    //     meta: { title: '白名单管理', icon: require('@/assets/images/center/menu_whitelist.png') }
    //   },
      {
        path: 'plans',
        name: 'Plans',
        component: () => import('@/views/center/product/plans.vue'),
        meta: { title: '流量明细', icon: require('@/assets/images/center/menu_plans.png') }
        },
        {
            path: 'completeOrder',
            name: 'CompleteOrder',
            component: () => import('@/views/completeOrder.vue'),
            meta: { show:false,title: '完成订单', icon: require('@/assets/images/center/menu_rocket_wallet.png') }
          },
    ]
  },

  {
    path: '/senior',
    component: Layout,
    redirect: '/senior/price',
    name: 'sProduct',
    meta: { title: '高级住宅代理', icon: require('@/assets/images/center/pre.png') },
    children: [
      {
        path: 'price',
        name: 'sPrice',
        component: () => import('@/views/center/senior/price.vue'),
        meta: { title: '价格', icon: require('@/assets/images/center/menu_price.png') }
      },
      {
        path: 'proxyAccess',
        name: 'sObtain',
        component: () => import('@/views/center/senior/obtain.vue'),
        meta: { title: '获取IP', icon: require('@/assets/images/center/menu_proxy.png') }
      },
    //   {
    //     path: 'proxyAccounts',
    //     name: 'sProxyAccounts',
    //     component: () => import('@/views/center/senior/proxyAccount.vue'),
    //     meta: { title: '代理账户', icon: require('@/assets/images/center/menu_proxy_accounts.png') }
    //   },
      {
        path: 'plans',
        name: 'sPlans',
        component: () => import('@/views/center/senior/plans.vue'),
        meta: { title: '流量明细', icon: require('@/assets/images/center/menu_plans.png') }
        },
        {
            path: 'completeOrderCopy',
            name: 'CompleteOrderCopy',
            component: () => import('@/views/completeOrderCopy.vue'),
            meta: { show:false,title: '完成订单', icon: require('@/assets/images/center/menu_rocket_wallet.png') }
          }
    ]
  },
  {
    path: '/account',
    component: Layout,
    redirect: '/account/profile',
    name: 'Account',
    meta: { title: '账户管理', icon: require('@/assets/images/center/menu_proxy_accounts.png') },
    children: [
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/center/account/profile.vue'),
        meta: { title: '个人信息', icon: require('@/assets/images/center/profile.png') }
      },
      {
        path: 'billings',
        name: 'Billings',
        component: () => import('@/views/center/account/billings.vue'),
        meta: { title: '购买记录', icon: require('@/assets/images/center/menu_billings.png') }
      },
    //   {
    //     path: 'referralProgram',
    //     name: 'ReferralProgram',
    //     component: () => import('@/views/center/account/referralProgram.vue'),
    //     meta: { title: '推广分佣', icon: require('@/assets/images/center/menu_referral.png') }
    //   }
    ]
  },

  {
    path: '/rocketWallet',
    component: Layout,
    redirect: '/rocketWallet/index',
    meta: { title: 'Rocket钱包', icon: require('@/assets/images/center/menu_rocket_wallet.png'), hide: true },
    children: [
      {
        path: 'index',
        name: 'rocketWalletIndex',
        component: () => import('@/views/center/rocketWallet.vue')
      },
      {
        path: 'completeOrderWallet',
        name: 'CompleteOrderWallet',
        component: () => import('@/views/completeOrderWallet.vue'),
        meta: { title: '完成订单', icon: require('@/assets/images/center/menu_rocket_wallet.png') }
        },
      
     
    ]
    },
    
    {
        path: '/referralProgram',
        component: Layout,
        redirect: '/referralProgram/index',
        meta: { title: '推广分佣', icon: require('@/assets/images/center/menu_referral.png'), hide: true },
        children: [
          {
            path: 'index',
            name: 'ReferralProgramIndex',
            component: () => import('@/views/center/account/referralProgram.vue'),          },
        ]
      },
  {
    path: '/helpCenter',
    meta: { title: '帮助中心', icon: require('@/assets/images/center/menu_help.png') }
  }
];
const centerRoutes_english = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login.vue'),
    meta: { title: 'Login', icon: 'home', hide: true }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/center/register.vue'),
    meta: { title: 'Register', icon: 'home', hide: true }
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: () => import('@/views/center/resetPassword.vue'),
    meta: { title: 'Reset password', icon: 'home', hide: true }
  },
  {
    path: '/confirmPassword',
    name: 'ConfirmPassword',
    component: () => import('@/views/confirmPassword.vue'),
    meta: { title: 'Confirm password', icon: 'home', hide: true }
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    meta: { title: 'Dashboard', icon: require('@/assets/images/center/menu_dashboard.png'), hide: true },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/dashboard.vue')
      }
    ]
  },
  {
    path: '/product',
    component: Layout,
    redirect: '/product/price',
    name: 'Product',
    meta: { title: 'Residential Proxy', icon: require('@/assets/images/center/home_icon.png') },
    children: [
      {
        path: 'price',
        name: 'Price',
        component: () => import('@/views/center/product/price.vue'),
        meta: { title: 'Price', icon: require('@/assets/images/center/menu_price.png') }
      },
      {
        path: 'proxyAccess',
        name: 'ProxyAccess',
        component: () => import('@/views/center/product/proxyAccess.vue'),
        meta: { title: 'Proxy Access', icon: require('@/assets/images/center/menu_proxy.png') }
      },
    //   {
    //     path: 'proxyAccounts',
    //     name: 'ProxyAccounts',
    //     component: () => import('@/views/center/product/proxyAccounts.vue'),
    //     meta: { title: 'Proxy accounts', icon: require('@/assets/images/center/menu_proxy_accounts.png') }
    //   },
    //   {
    //     path: 'whitelist',
    //     name: 'Whitelist',
    //     component: () => import('@/views/center/product/whitelist.vue'),
    //     meta: { title: 'IP Whitelist', icon: require('@/assets/images/center/menu_whitelist.png') }
    //   },
      {
        path: 'plans',
        name: 'Plans',
        component: () => import('@/views/center/product/plans.vue'),
        meta: { title: 'Traffic Details', icon: require('@/assets/images/center/menu_plans.png') }
        },
        {
            path: 'completeOrder',
            name: 'CompleteOrder',
            component: () => import('@/views/completeOrder.vue'),
            meta: { show:false,title: 'Complete order', icon: require('@/assets/images/center/menu_rocket_wallet.png') }
          },
       
    ]
  },
  {
    path: '/senior',
    component: Layout,
    redirect: '/senior/price',
    name: 'Senior',
    meta: { title: 'Premium Proxy', icon: require('@/assets/images/center/pre.png') },
    children: [
      {
        path: 'price',
        name: 'sPrice',
        component: () => import('@/views/center/senior/price.vue'),
        meta: { title: 'Price', icon: require('@/assets/images/center/menu_price.png') }
      },
      {
        path: 'proxyAccess',
        name: 'sObtain',
        component: () => import('@/views/center/senior/obtain.vue'),
        meta: { title: 'Proxy Access', icon: require('@/assets/images/center/menu_proxy.png') }
      },
    //   {
    //     path: 'proxyAccounts',
    //     name: 'sProxyAccounts',
    //     component: () => import('@/views/center/senior/proxyAccount.vue'),
    //     meta: { title: 'Proxy accounts', icon: require('@/assets/images/center/menu_proxy_accounts.png') }
    //   },
      {
        path: 'plans',
        name: 'sPlans',
        component: () => import('@/views/center/senior/plans.vue'),
        meta: { title: 'Traffic Details', icon: require('@/assets/images/center/menu_plans.png') }
        },
        {
            path: 'completeOrderCopy',
            name: 'CompleteOrderCopy',
            component: () => import('@/views/completeOrderCopy.vue'),
            meta: { show:false,title: 'Complete order', icon: require('@/assets/images/center/menu_rocket_wallet.png') }
          }
    ]
  },
  {
    path: '/account',
    component: Layout,
    redirect: '/account/profile',
    name: 'Account',
    meta: { title: 'Account', icon: require('@/assets/images/center/menu_proxy_accounts.png') },
    children: [
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/views/center/account/profile.vue'),
        meta: { title: 'Profile', icon: require('@/assets/images/center/profile.png') }
      },
      {
        path: 'billings',
        name: 'Billings',
        component: () => import('@/views/center/account/billings.vue'),
        meta: { title: 'Billings', icon: require('@/assets/images/center/menu_billings.png') }
      },
    //   {
    //     path: 'referralProgram',
    //     name: 'ReferralProgram',
    //     component: () => import('@/views/center/account/referralProgram.vue'),
    //     meta: { title: 'Affiliate', icon: require('@/assets/images/center/menu_referral.png') }
    //   }
    ]
  },

  {
    path: '/rocketWallet',
    component: Layout,
    redirect: '/rocketWallet/index',
    meta: { title: 'Rocket Wallet', icon: require('@/assets/images/center/menu_rocket_wallet.png'), hide: true },
    children: [
      {
        path: 'index',
        name: 'rocketWalletIndex',
        component: () => import('@/views/center/rocketWallet.vue')
        },
        {
            path: 'completeOrderWallet',
            name: 'CompleteOrderWallet',
            component: () => import('@/views/completeOrderWallet.vue'),
            meta: { title: 'Complete order', icon: require('@/assets/images/center/menu_rocket_wallet.png') }
            },
          
    ]
    },
    {
        path: '/referralProgram',
        component: Layout,
        redirect: '/referralProgram/index',
        meta: { title: 'Affiliate', icon: require('@/assets/images/center/menu_referral.png'), hide: true },
        children: [
          {
            path: 'index',
            name: 'ReferralProgramIndex',
            component: () => import('@/views/center/account/referralProgram.vue'),          },
        ]
      },
  {
    path: '/helpCenter',
    meta: { title: 'Help center', icon: require('@/assets/images/center/menu_help.png') }
  }
];
const urlParams = new URLSearchParams(window.location.search);
const langParam = urlParams.get('lang');
 
// 根据查询参数设置 localStorage 中的语言
if (langParam === 'zh') {
  localStorage.setItem('langrage', 'chinese');
} else if(langParam === 'en'){
  localStorage.setItem('langrage', 'english'); // 默认语言
} else {
}



const lang = localStorage.getItem('langrage');
let centerRoutes = [];
if (lang === 'chinese') {
  centerRoutes = centerRoutes_chinese;
} else if (lang === 'english') {
  centerRoutes = centerRoutes_english;
} else {
  localStorage.setItem('langrage', 'english');
  centerRoutes = centerRoutes_english;
}
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: centerRoutes
});
//校验当前登录的token和user数据,没有就做相应操作
router.beforeEach(async (to, from, next) => {
  let token = localStorage.getItem('token');
  let routs = ['/register', '/Register', '/login', '/resetPassword', '/confirmPassword']; 
    if (routs.indexOf(to.path) >= 0) {
      console.log('先不判断token,判断to.path进入第一步',to.path);
      
    next();
    }
    else if (token) {
        console.log('有token进入第二步');
        if (!store.state.user.userInfo) {
        console.log('如果没有个人信息调用获取信息,这是第2.1步');
        
      await store.dispatch('user/getInfo');
    }
    next();
    } else {
      next(next('/login'));
  }
});
export default router;
