<script setup>
import Sidebar from './components/Sidebar.vue'
import Navbar from './components/Navbar.vue'
import AppMain from './components/AppMain.vue'
import { useStore } from 'vuex'

const store = useStore()
store.dispatch('user/getInfo')
</script>

<template>
  <div class="app-wrapper">
    <Sidebar class="sidebar-container" />
    <div class="main-container">
      <Navbar />
      <AppMain />
    </div>
  </div>
</template>

<style lang="less" scoped>
.app-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
}
.main-container {
  height: 100vh;
  flex-grow: 1;
  overflow: auto;
  background: #f8f8f8;
}
</style>
