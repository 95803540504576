<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()

const key = computed(() => route.path)
console.log(key, '7===')
</script>

<template>
  <section class="app-main">
    <router-view />
  </section>
</template>

<style scoped lang="less">
.app-main {
  box-sizing: border-box;
  padding: 8rem 2rem 2rem;
}
</style>
