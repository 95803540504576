import http from '@/utils/http';

/**
 * 获取国家下拉框
 * countryen
 */
export const queryNationListApi = (params) => {
  return http({
    url: '/clientb/sysnation/queryNationList',
    method: 'GET',
    params
  });
};

/**
 * 根据国家简称获取州
 * nation
 */
export const getStatusApi = (params) => {
  return http({
    url: '/clientb/sysnation/getStatus',
    method: 'GET',
    params
  });
};

/**
 * 根据国家简称州获取城市
 * nation
 * region
 */
export const getCityApi = (params) => {
  return http({
    url: '/clientb/sysnation/getCity',
    method: 'GET',
    params
  });
};

/**
 * 根据国家简称获取运营商
 * nation
 */
export const getCityIspApi = (params) => {
  return http({
    url: '/clientb/sysnation/getCityIsp',
    method: 'GET',
    params
  });
};

/**
 * 获取代理账户列表
 *
 */
export const getSysagencyListApi = (params) => {
  return http({
    url: '/clientb/sysagency/list',
    method: 'GET',
    params
  });
};

// 判断当前IP是否在白名单
export const ipJudgment = (params) => {
  return http({
    url: '/clientb/syswhite/ipJudgment',
    method: 'GET',
    params
  });
};

export const getips = (params) =>{
  return http({
    url: '/clientb/syswhite/getIP',
    method: 'GET',
    params
  });
}
