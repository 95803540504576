import { getInfoApi, googleLoginApi, logout } from '@/apis/login';
import {remindContent} from '@/apis/common.js'
const user = {
  namespaced: true,
  state: {
    userInfo: null,
      token: '' || localStorage.getItem('token'),
    userMessage:[]
  },
  mutations: {
    TOKEN(state, token) {
      state.token = token;
            localStorage.setItem('token', token);
    },
    USER_INFO(state, info) {
      state.userInfo = info;
      localStorage.setItem('userInfo', JSON.stringify(info));
      },
      USER_MESSAGE(state, info) {
        state.userMessage=info
      },
      CHANGE_MESSAGE_TYPE(state, info) {
        state.userMessage.forEach(el => {
            if (el.id == info) {
                // el.isRead==1?el.isRead=2:el.isRead=1
                el.isRead=1
            }
        });
      }
  },
  actions: {
    saveToken({ commit }, data) {
      commit('TOKEN', data);
    },

    saveUserInfo({ commit }, data) {
      commit('USER_INFO', data);
    },

    //google快捷登录
    googleLogin({ commit, state }) {
      return new Promise((resolve, reject) => {
        googleLoginApi(state.credential)
          .then((response) => {
            console.log(response, '32===');
            // const { user } = response
            // commit('USER_INFO', user)
            // resolve(user)
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
//获取小铃铛信息
      getRemindContent({ commit, state }) {
          return new Promise((resolve, reject) => {
              const response = localStorage.getItem('response')
              console.log('response的数据',JSON.parse(response));
              
        remindContent({ userId: JSON.parse(response).user.userId }).then(res => {
            commit('USER_MESSAGE', res.data)
        })
    })
},
    //用户信息(现在绑定了一条获取用户提醒的接口,这个接口不需要强制同步)
    getInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            
        getInfoApi()
          .then((response) => {
            // const { user } = response
            commit('USER_INFO', response);
              localStorage.setItem('response', JSON.stringify(response));
              remindContent({ userId: response.user.userId }).then(res => {
                commit('USER_MESSAGE', res.data);
            })
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 退出登录
    logout({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout({ Authorization: state.token })
          .then(() => {
              commit('RESET_STATE');
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
};
export default user;
