import axios from 'axios';
import { ElMessage } from 'element-plus';
import clipBoard from 'vue-clipboard3';
const { toClipboard } = clipBoard(); // 一键复制
import { inject } from 'vue';
import {getips} from '@/apis/getIP.js'

const lang = localStorage.getItem('langrage') || 'chinese';
// 引入图片
export const require = (imgPath) => {
    console.log("模块引入")
  try {
    const handlePath = imgPath.replace('@', '..');
    return new URL(handlePath, import.meta.url).href;
  } catch (error) {
    console.warn(error);
  }
};

// 获取IP地址
export const getIP = async () => {
  try {
    // const { data } = await axios.get('https://api.ipify.org?format=json');
    let res = await getips();
    const data = {
      ip: res.data
    }
    return data;
  } catch (error) {
    console.error('Error fetching IP:', error);
  }
};

// 复制文本
export const copyText = async (value) => {
  try {
      await toClipboard(value);
      
      ElMessage({
        grouping: true,
      message: lang == 'chinese' ? '复制成功' : 'Replicating Success',
      type: 'success'
    });
  } catch (e) {
      ElMessage({
        grouping: true,
      message: e,
      type: 'error'
    });
  }
};

//生成八位随机数（数字+字母组合）
export const randomRange = (min, max) => {
  let returnStr = '',
    range = max ? Math.round(Math.random() * (max - min)) + min : min,
    charStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < range; i++) {
    let index = Math.round(Math.random() * (charStr.length - 1));
    returnStr += charStr.substring(index, index + 1);
  }
  return returnStr;
};

//生成八位随机数（数字）
export const randomNumber = (num) => {
  let randomNumber = Math.floor(Math.random() * 100000000);
  return randomNumber.toString().padStart(num, '0');
  // return returnStr;
};
//生成八位随机数（数字前是8或9）
export const generateNineDigitRandomNumber=()=> {
    // 生成第一位，要么是8要么是9
    let firstDigit = Math.floor(Math.random() * 2) + 8;
 
    // 生成后8位随机数
    let remainingDigits = '';
    for (let i = 0; i < 8; i++) {
        remainingDigits += Math.floor(Math.random() * 10);
    }
    let randomNumber = firstDigit + remainingDigits;
 
    return randomNumber;
}