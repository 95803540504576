<template>
    <el-config-provider :locale="locale">
        <router-view />
    </el-config-provider>
</template>
<script setup>
import '@/views/center/base.less';

import { computed, onMounted, ref, provide } from 'vue';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import en from 'element-plus/dist/locale/en.mjs';
import chinese from '@/assets/json/chinese.js';
import english from '@/assets/json/english.js';
import { useStore } from 'vuex';
const store = useStore();
const language = ref('zh-cn');
import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
console.log('进入app');

let route = useRoute();
let router = useRouter();
// const toggle = () => {
//   language.value = language.value === 'zh-cn' ? 'en' : 'zh-cn'
// }
let lang = localStorage.getItem('langrage');
if (lang === 'english') {
    provide('langrage', english);
} else {
    provide('langrage', chinese);
}
const locale = computed(() => (lang === 'chinese' ? zhCn : en));
console.log('locale:', locale.value);
// 解决el-table ResizeObserver loop completed with undelivered notifications.报错
const debounce = (fn, delay) => {
    let timer = null;
    return function () {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function () {
            fn.apply(context, args);
        }, delay);
    };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback) {
        callback = debounce(callback, 16);
        super(callback);
    }
};
</script>
