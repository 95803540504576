<template>
  <el-breadcrumb class="flex-y-center">
    <transition-group>
      <el-breadcrumb-item v-for="(item, index) in breadcrumbs" :key="item.path">
        <span
          v-if="
            item.redirect === 'noredirect' || index === breadcrumbs.length - 1
          "
          class="color-gray-400"
          >{{ item.meta.title }}</span
        >
        <a v-else @click.prevent="handleLink(item)">
          {{ item.meta.title }}
        </a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { compile } from 'path-to-regexp'
import { onBeforeMount, ref, watch } from 'vue'
const router = useRouter()
const lang = localStorage.getItem('langrage');
const currentRoute = useRoute()
const pathCompile = (path) => {
  const { params } = currentRoute
  const toPath = compile(path)
  return toPath(params)
}

const breadcrumbs = ref([])

function getBreadcrumb() {
  let matched = currentRoute.matched.filter(
    (item) => item.meta && item.meta.title
  )
  console.log(matched, '39===')
  const first = matched[0]
  if (!isDashboard(first)) {
    if(lang === 'chinese'){
      matched = [{ path: '/dashboard', meta: { title: '首页' } }].concat(matched)
    } else if( lang === 'english'){
      matched = [{ path: '/dashboard', meta: { title: 'Home' } }].concat(matched)
    }
  }
  console.log(currentRoute,'50===');
  breadcrumbs.value = matched.filter((item) => {
    console.log(item,'========================');
    if(item.name === "CompleteOrder" && currentRoute.query["money"]){
      if(currentRoute.query["type"]){
        if(lang === 'chinese'){
          matched[1].meta.title = '价格';
        } else if( lang === 'english'){
          matched[1].meta.title = 'Price';
        }
      }
      if(!currentRoute.query["type"]){
        if(lang === 'chinese'){
          matched[1].meta.title = 'Rocket钱包';
        } else if( lang === 'english'){
          matched[1].meta.title = 'Rocket Wallet';
        }
      }
    }
    if(item.redirect === "/rocketWallet/index"){
      if(lang === 'chinese'){
        matched[1].meta.title = 'Rocket钱包';
      } else if( lang === 'english'){
        matched[1].meta.title = 'Rocket Wallet';
      }
    }
    return item.meta && item.meta.title && item.meta.breadcrumb !== false
  })
  console.log(breadcrumbs.value, '47===')
}

function isDashboard(route) {
  const name = route && route.name
  if (!name) {
    return false
  }
  return (
    name.toString().trim().toLocaleLowerCase() ===
    'Dashboard'.toLocaleLowerCase()
  )
}

function handleLink(item) {
  const { redirect, path } = item
  if (redirect) {
    router.push(redirect).catch((err) => {
      console.warn(err)
    })
    return
  }
  router.push(pathCompile(path)).catch((err) => {
    console.warn(err)
  })
}

watch(
  () => currentRoute.path,
  (path) => {
    if (path.startsWith('/redirect/')) {
      return
    }
    getBreadcrumb()
  }
)

onBeforeMount(() => {
  getBreadcrumb()
})
</script>

<style lang="less" scoped>
// 覆盖 element-plus 的样式
.el-breadcrumb__inner,
.el-breadcrumb__inner a {
  font-weight: 400 !important;
}
</style>
